<template>
  <section class="card_style mt-0">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="mb-0">
          {{ $t("Terms") }}
        </h4>
      </div>
    </div>

    <form @submit.prevent="addTerm()">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="form-group">
            <label for="">{{ $t("description") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("description") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group mb-5"
                      v-for="(term, index) in terms"
                      :key="index"
                    >
                      <label for="description" class="form-label"
                        >{{ $t("description") }} ({{ term.lang }})</label
                      >
                      <Editor
                        v-model="term.description"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn PrimaryButton mt-4">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import Editor from "primevue/editor";
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "policyIndex",
  components: {
    Editor,
    AddFavDailog,
  },
  data() {
    return {
      languages: ["ar", "en", "id"],
      terms: [],
      showButton: false,
    };
  },
  methods: {
    setTermsContent() {
      this.languages.forEach((ele) => {
        this.terms.push({
          lang: ele,
          description: "",
        });
      });
    },
    async fetchTermsData() {
      const formData = new FormData();
      if(this.$route.params.type === "portal") {
        formData.append("is_portal", 1);
      }

      await axios.post(`/fetch_term`, formData).then(({ data }) => {
        if (data.data.descriptions) {
          this.terms = data.data.descriptions.map((desc) => {
            return {
              lang: desc.locale,
              description: desc.description,
            };
          });
          this.showButton = false;
        } else {
          this.showButton = true;
        }
        //   console.log(data.data, "terms");
        //   this.terms = data.data.descriptions;
      });
    },
    async addTerm() {
      let termObj = {};
      this.terms.forEach((ele) => {
        termObj[`description_${ele.lang}`] = ele.description;
      });
      if(this.$route.params.type === "portal") {
        termObj.is_portal = 1;
      }
      await axios
        .post(`/website/term`, termObj)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // window.location.reload();
          this.showButton = false;
        })
        .catch(({ error }) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.setTermsContent();
    this.fetchTermsData();
  },
};
</script>

<style></style>
