<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style rate_code" v-else>
    <div class="main_header_style">
      <h4>
        {{ $t("Rate code") }} / {{ fathy.name }} / {{ fathy.contract }} /{{
          fathy.hotel_name
        }}
      </h4>
    </div>
    <form class="form_topic w-100" @submit.prevent="editRateCode()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("name_rate") }}</label>

            <input
              type="text"
              class="form-control"
              :placeholder="$t('name_rate')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <div class="d-flex justify-content-between w-100">
              <label class="form-label"> {{ $t("country") }}</label>
              <div
                class="col-6 form-group justify-content-end gap-2 d-flex align-items-center"
              >
                <label for="nation" class="mb-0"
                  >{{ allNations ? $t("all_nations") : $t("choose_country") }}
                </label>
                <InputSwitch v-model="allNations" class="ml-2" id="nation" />
              </div>
            </div>
            <Multiselect
              v-model="country_id"
              track-by="id"
              label="title"
              :options="countries"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
              :disabled="allNations"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("From") }}</label>
            <datepicker
              v-model="fromDate"
              name="durationFrom"
              :placeholder="$t('From')"
              :disabled-dates="{
                to: new Date(fromDateSeasons),
                from: new Date(toDateSeasons),
              }"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("To") }}</label>
            <datepicker
              v-model="toDate"
              name="durationTo  "
              :placeholder="$t('To')"
              :disabled-dates="{
                to: new Date(fromDateSeasons),
                from: new Date(toDateSeasons),
              }"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("employ") }}</label>
            <Multiselect
              v-model="employees_id"
              :options="empolyment"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
            ></Multiselect>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("rooms") }}</label>
            <Multiselect
              v-model="hotelRoom"
              :options="hotelRooms"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :multiple="true"
              :custom-label="hotelRoomName"
              :hide-selected="true"
            ></Multiselect>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("meals") }}</label>
            <Multiselect
              v-model="meal"
              :options="meals"
              label="title"
              track-by="meal_type_id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
            ></Multiselect>
          </div>
        </div>

        <h2 v-if="hotelRoomArray?.length > 0">{{ $t("rooms") }}</h2>
        <!-- Rooms-->
        <div class="meals_section d-flex flex-wrap gap-3 mb-3">
          <div
            class="card"
            v-for="(room, index) in hotelRoomArray"
            :key="index"
          >
            <h4>
              {{ $t("Type Of Room") }}
              {{ room.name ? room.name : room.title }}
            </h4>
            <div class="row align-items-center">
              <p class="col-4 text">{{ $t("cost") }}</p>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="0"
                  v-model="room.week_day_cost"
                />
              </div>

              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="0"
                  v-model="room.week_end_cost"
                />
              </div>
              <p class="col-4 text gap-2 mb-0">
                <span>
                  <Checkbox v-model="room.switchB2c" :binary="true" />
                </span>
                {{ $t("selling_b2c") }}
              </p>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="!room.switchB2c"
                  v-model="room.week_day_b2c_price"
                />
              </div>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="!room.switchB2c"
                  v-model="room.week_end_b2c_price"
                />
              </div>

              <p class="col-4 text gap-2 mb-0">
                <span>
                  <Checkbox v-model="room.switchB2b" :binary="true" />
                </span>
                {{ $t("selling_b2b") }}
              </p>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="!room.switchB2b"
                  v-model="room.week_day_b2b_price"
                />
              </div>
              <div class="col-4 form-group">
                <label
                  :for="`cost${room.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="!room.switchB2b"
                  v-model="room.week_end_b2b_price"
                  :id="`cost${room.id}`"
                />
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-3" />
        <h2
          class="mt-2 col-12 d-flex gap-2 align-items-center"
          v-if="hotelViewArray?.length > 0"
        >
          <span>{{ $t("Views") }}</span>
          <span class="fs-6 text-secondary d-flex gap-2 align-items-center">
            {{ $t("One Selling Price") }} <InputSwitch v-model="checkedView" />
          </span>
        </h2>
        <!-- views -->
        <div class="meals_section d-flex flex-wrap gap-3 mb-3">
          <div
            class="card"
            v-for="(view, index) in hotelViewArray"
            :key="index"
          >
            <div class="row align-items-center">
              <h4 class="col-6">{{ view.title }}</h4>
              <div
                class="col-6 form-group justify-content-end gap-2 d-flex align-items-center"
              >
                <label for="include" class="mb-0"
                  >{{ view.include ? $t("includes") : $t("excludes") }}
                </label>
                <InputSwitch
                  v-model="view.include"
                  class="ml-2"
                  :trueValue="updateViewInclude(view)"
                  :id="`include${index}`"
                />
              </div>
              <p class="col-2 text">{{ $t("cost") }}</p>
              <div class="col-5 form-group">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="view.include"
                  v-model="view.week_day_cost"
                />
              </div>

              <div class="col-5 form-group">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="view.include"
                  v-model="view.week_end_cost"
                />
              </div>

              <p class="col-2 text gap-2 mb-0" v-show="!checkedView">
                {{ $t("selling_b2c") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="view.include"
                  v-model="view.week_day_b2c_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="view.include"
                  v-model="view.week_end_b2c_price"
                />
              </div>
              <p class="col-2 text gap-2 mb-0" v-show="!checkedView">
                {{ $t("selling_b2b") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="view.include"
                  v-model="view.week_day_b2b_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`cost${view.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="view.include"
                  v-model="view.week_end_b2b_price"
                  :id="`cost${view.id}`"
                />
              </div>
              <div
                class="total row m-0 p-0 align-items-center"
                v-show="checkedView"
              >
                <p class="col-2 text gap-2 mb-0">
                  {{ $t("selling") }}
                </p>
                <div class="col-5 form-group">
                  <label
                    :for="`selling${view.id}`"
                    class="form-label text-info"
                    >{{ $t("Weekday") }}</label
                  >
                  <input
                    type="number"
                    name=""
                    :id="`selling${view.id}`"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedView || view.include"
                    @input="totalB2bB2cDay(view)"
                    v-model="view.totalB2bB2cDay"
                  />
                </div>
                <div class="col-5 form-group">
                  <label
                    :for="`cost${view.id}`"
                    class="form-label text-success"
                    >{{ $t("Weekend") }}</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedView || view.include"
                    v-model="view.totalB2bB2cEnd"
                    @input="totalB2bB2cEnd(view)"
                    :id="`cost${view.id}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-3" />
        <!-- Meals -->
        <h2
          v-if="mealsArray?.length > 0"
          class="d-flex align-items-center my-3 gap-3"
        >
          <span>{{ $t("meals") }}</span>
          <span class="fs-6 text-secondary d-flex gap-2 align-items-center">
            {{ $t("One Selling Price") }}
            <InputSwitch v-model="checkedMeal" class="ml-2" />
          </span>
        </h2>
        <div class="meals_section d-flex flex-wrap gap-3 mb-3">
          <div class="card" v-for="(meal, index) in mealsArray" :key="index">
            <div class="row">
              <h4 class="col-6">{{ meal.title }}</h4>
              <div
                class="col-6 form-group justify-content-end gap-2 d-flex align-items-center"
              >
                <label for="include" class="mb-0"
                  >{{ meal.include ? $t("includes") : $t("excludes") }}
                </label>
                <InputSwitch
                  v-model="meal.include"
                  :trueValue="updateMealInclude(meal)"
                  class="ml-2"
                  :id="`include${index}`"
                />
              </div>
            </div>
            <div class="row align-items-center">
              <p class="col-2 text">{{ $t("cost") }}</p>
              <div class="col-5 form-group">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="meal.include"
                  v-model="meal.week_day_cost"
                />
              </div>

              <div class="col-5 form-group">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="meal.include"
                  v-model="meal.week_end_cost"
                />
              </div>
              <p class="col-2 text gap-2 mb-0" v-show="!checkedMeal">
                {{ $t("selling_b2c") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="meal.include"
                  v-model="meal.week_day_b2c_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="meal.include"
                  v-model="meal.week_end_b2c_price"
                />
              </div>

              <p class="col-2 text gap-2 mb-0" v-show="!checkedMeal">
                {{ $t("selling_b2b") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="meal.include"
                  v-model="meal.week_day_b2b_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`cost${meal.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="meal.include"
                  v-model="meal.week_end_b2b_price"
                  :id="`cost${meal.id}`"
                />
              </div>
              <div
                class="total row m-0 p-0 align-items-center"
                v-show="checkedMeal"
              >
                <p class="col-2 text gap-2 mb-0">
                  {{ $t("selling") }}
                </p>
                <div class="col-5 form-group">
                  <label
                    :for="`selling${meal.id}`"
                    class="form-label text-info"
                    >{{ $t("Weekday") }}</label
                  >
                  <input
                    type="number"
                    name=""
                    :id="`selling${meal.id}`"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedMeal || meal.include"
                    @input="totalB2bB2cDay(meal)"
                    v-model="meal.totalB2bB2cDay"
                  />
                </div>
                <div class="col-5 form-group">
                  <label
                    :for="`cost${meal.id}`"
                    class="form-label text-success"
                    >{{ $t("Weekend") }}</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedMeal || meal.include"
                    v-model="meal.totalB2bB2cEnd"
                    @input="totalB2bB2cEnd(meal)"
                    :id="`cost${meal.id}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="col-12 mb-5">
          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(term, index) in Form.terms"
                :key="index"
                :header="$t('terms') + ' - ' + term.lang"
              >
                <div class="form-group mb-0">
                  <Editor v-model="term.name" editorStyle="height: 120px" />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
          </div>
          <div class="col-12 mb-2" style="margin: 1rem 0">
            <button type="submit" class="btn PrimaryButton">
              <i
                class="fa-solid fa-spinner fa-spin-pulse"
                v-if="submitLoader"
              ></i>
              <span v-else>{{ $t("save") }}</span>
            </button>
          </div>

          <hr />
        </div>
        <!-- types room -->
        <!-- <ul class="types">
          <li v-for="(type, index) in service_types" :key="index">
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                class="wizard-required"
                v-model="selectedServiceType"
                :value="type.value"
                :id="'default' + type.value"
              />
              <label :for="'default' + type.value">
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul> -->
        <!-- types room -->

        <!-- rooms -->
        <!-- rooms -->

        <!-- start input group -->

        <!-- finish input group -->
      </div>
    </form>
    <div class="col-12">
      <div class="available_rooms">
        <div class="not_available" v-if="!daysArray.length">
          <img
            src="@/assets/media/image/available_rooms.png"
            alt="available_rooms"
          />
          <h5 class="text">
            {{ $t("Enter the required data to get the available rooms") }}
          </h5>
        </div>
        <div v-else v-for="(room, index) in daysArray" :key="index">
          <div class="row mb-3" v-if="room.days.length > 0">
            <div class="col-6 d-flex flex-column">
              <h4>{{ room.name ? room.name : room.title }}</h4>
              <div class="d-flex align-items-center gap-3">
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    class="form-check-input mt-0"
                    type="radio"
                    name="flexRadioDefault"
                    id="all"
                    value="all"
                    v-model="room.filterDays"
                  />
                  <label class="form-label mb-0">{{ $t("all") }}</label>
                </div>
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    class="form-check-input mt-0"
                    type="radio"
                    name="flexRadioDefault"
                    id="specific"
                    value="specific"
                    v-model="room.filterDays"
                  />
                  <label class="form-label mb-0">{{ $t("specific") }}</label>
                </div>
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    class="form-check-input mt-0"
                    type="radio"
                    name="flexRadioDefault"
                    id="weekday"
                    value="weekday"
                    v-model="room.filterDays"
                  />
                  <label class="form-label mb-0">{{ $t("weekday") }}</label>
                </div>
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    class="form-check-input mt-0"
                    type="radio"
                    name="flexRadioDefault"
                    id="weekend"
                    value="weekend"
                    v-model="room.filterDays"
                  />
                  <label class="form-label mb-0">{{ $t("weekend") }}</label>
                </div>
              </div>
              <div
                v-if="room.filterDays === 'all'"
                class="d-flex gap-3 flex-wrap"
              >
                <div class="form-group">
                  <label class="form-label">{{ $t("Cost - Weekday") }} </label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.cost_weekday"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{ $t("Cost - Weekend") }} </label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.cost_weekend"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2B - Weekday (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2b_weekday"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2B - Weekend (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2b_weekend"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2C - Weekday (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2c_weekday"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2C - Weekend (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2c_weekend"
                  />
                </div>
              </div>
              <div v-else-if="room.filterDays === 'specific'" class="row">
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("from") }}</label>
                  <datepicker
                    v-model="room.fromDate"
                    name="durationFrom"
                    @input="filterDaysMethod(room)"
                    :placeholder="$t('From')"
                    :disabled-dates="{
                      to: new Date(fromDateSeasons),
                      from: new Date(toDateSeasons),
                    }"
                  ></datepicker>
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("to") }}</label>
                  <datepicker
                    v-model="room.toDate"
                    name="durationTo"
                    @input="filterDaysMethod(room)"
                    :placeholder="$t('To')"
                    :disabled-dates="{
                      to: new Date(fromDateSeasons),
                      from: new Date(toDateSeasons),
                    }"
                  ></datepicker>
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("Cost - Weekday") }} </label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.cost_weekday"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("Cost - Weekdend") }} </label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.cost_weekend"
                  />
                </div>

                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2B - Weekday (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2b_weekday"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2B - Weekend (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2b_weekend"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2C - Weekday (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2c_weekday"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2C - Weekend (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2c_weekend"
                  />
                </div>
              </div>
              <div class="row" v-else-if="room.filterDays === 'weekend'">
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("Cost - Weekdend") }} </label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.cost_weekend"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2B - Weekend (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2b_weekend"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2C - Weekend (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2c_weekend"
                  />
                </div>
              </div>
              <div class="row" v-else-if="room.filterDays === 'weekday'">
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("Cost - Weekday") }} </label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.cost_weekday"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2B - Weekday (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2b_weekday"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2C - Weekday (Selling)")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    @input="filterDaysMethod(room)"
                    v-model="room.b2c_weekday"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-6 price_quantity justify-content-end align-items-end gap-3"
            >
              <stopSelling
                :daysArray="daysArray"
                :fetchRateCodeData="fetchRateCodeData"
              />

              <button
                class="btn btn-danger"
                @click="updateDays(room, 0, index)"
              >
                <i
                  class="fa-solid fa-spinner fa-spin-pulse"
                  v-if="room?.loader"
                ></i>
                <i v-else class="fa-solid fa-trash"></i>
              </button>

              <router-link
                class="btn PrimaryButton"
                style="margin-inline-start: unset"
                :to="`/ratecode/history/${room.id}`"
              >
                <i class="fa-solid fa-history"></i>
              </router-link>

              <button
                class="btn PrimaryButton"
                style="margin-inline-start: unset"
                @click="updateDays(room, 1)"
              >
                <i
                  class="fa-solid fa-spinner fa-spin-pulse"
                  v-if="room.loader"
                ></i>
                <span class="d-flex align-items-center gap-1" v-else
                  ><i class="fa-solid fa-floppy-disk"></i>
                  {{ $t("update") }}</span
                >
              </button>
            </div>
          </div>
          <div class="details_price" v-if="room.days.length > 0">
            <div class="details_room">
              <div
                class="table-responsive"
                v-if="room.filterDaysArray.length > 0"
              >
                <table class="table table-bordered mb-0 overflow-x-auto">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t("date") }}</th>
                      <th
                        scope="col"
                        v-for="(day, item) in room.filterDaysArray"
                        :key="item"
                        :style="{
                          'background-color': day.is_weekend
                            ? '#0a909b'
                            : '#535353',
                        }"
                      >
                        <!-- <div class="quantity">
                            <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantity(index)"
                            >
                              <i class="fa-solid fa-minus"></i>
                            </button>
                            <span class="number">{{ room.count }}</span>
                            <button
                              class="btn add"
                              type="button"
                              @click="addQuantity(index)"
                            >
                              <i class="fa-solid fa-plus"></i>
                            </button>
                          </div> -->
                        <div
                          class="d-flex align-items-baseline justify-content-between"
                        >
                          <div class="d-flex flex-column">
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-black' : 'text-white',
                              ]"
                            >
                              {{ formatDate(day.date) }}
                            </p>

                            <p
                              class="hijri mb-0"
                              :class="[
                                day.is_weekend ? 'text-black' : 'text-white',
                              ]"
                            >
                              {{ updateFromHijriDate(new Date(day.date)) }}
                            </p>
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-black' : 'text-white',
                              ]"
                            >
                              {{ getWeekday(new Date(day.date)) }}
                            </p>
                          </div>
                          <i
                            class="fa-solid fa-circle fa-2xs text-white"
                            v-if="day.has_edited == '1'"
                          ></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t("cost") }}</th>
                      <td
                        v-for="(day, item) in room.filterDaysArray"
                        :key="item"
                      >
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          v-model="day.cost"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">B2B</th>
                      <td
                        v-for="(day, item) in room.filterDaysArray"
                        :key="item"
                      >
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          v-model="day.b2b_price"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">B2C</th>
                      <td
                        v-for="(day, item) in room.filterDaysArray"
                        :key="item"
                      >
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          v-model="day.b2c_price"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive" v-else>
                <table class="table table-bordered mb-0 overflow-x-auto">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t("date") }}</th>
                      <th
                        scope="col"
                        v-for="(day, item) in room.days"
                        :key="item"
                        :style="{
                          'background-color': day.is_weekend
                            ? '#0a909b'
                            : '#535353',
                        }"
                      >
                        <!-- <div class="quantity">
                            <button
                              class="btn minus"
                              type="button"
                              @click="minusQuantity(index)"
                            >
                              <i class="fa-solid fa-minus"></i>
                            </button>
                            <span class="number">{{ room.count }}</span>
                            <button
                              class="btn add"
                              type="button"
                              @click="addQuantity(index)"
                            >
                              <i class="fa-solid fa-plus"></i>
                            </button>
                          </div> -->
                        <div
                          class="d-flex align-items-baseline justify-content-between"
                        >
                          <div class="d-flex flex-column">
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-black' : 'text-white',
                              ]"
                            >
                              {{ formatDate(day.date) }}
                            </p>

                            <p
                              class="hijri mb-0"
                              :class="[
                                day.is_weekend ? 'text-black' : 'text-white',
                              ]"
                            >
                              {{ updateFromHijriDate(new Date(day.date)) }}
                            </p>
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-black' : 'text-white',
                              ]"
                            >
                              {{ getWeekday(new Date(day.date)) }}
                            </p>
                          </div>
                          <i
                            class="fa-solid fa-circle fa-2xs text-white"
                            v-if="day.has_edited == '1'"
                          ></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t("cost") }}</th>
                      <td v-for="(day, item) in room.days" :key="item">
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          v-model="day.cost"
                          :disabled="day.is_stop == 1 ? true : false"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">B2B</th>
                      <td v-for="(day, item) in room.days" :key="item">
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          v-model="day.b2b_price"
                          :disabled="day.is_stop == 1 ? true : false"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">B2C</th>
                      <td v-for="(day, item) in room.days" :key="item">
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          v-model="day.b2c_price"
                          :disabled="day.is_stop == 1 ? true : false"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr v-if="room.days.length > 0" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";
import Checkbox from "primevue/checkbox";
import InputSwitch from "primevue/inputswitch";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Editor from "primevue/editor";
import stopSelling from "@/components/hotels/contrackt/season/stopSellingDialog.vue";
export default {
  name: "editRateCode",
  components: {
    Datepicker,
    Multiselect,
    InputSwitch,
    Loading,
    Checkbox,
    TabView,
    TabPanel,
    Editor,
    stopSelling,
  },
  data() {
    return {
      is_stop: false,
      languages: ["ar", "en", "id"],

      Form: {
        terms: [],
      },
      daysFilter: "",
      allNations: true,
      name: "",
      submitLoader: false,
      checkedView: false,
      checkedMeal: false,
      loading: true,
      duration: null,
      countries: [],
      country_id: [],
      selectedServiceType: 1,
      service_types: [{ value: 1 }, { value: 2 }, { value: 3 }],
      price_cost_b2b_week_end: false,
      cost_b2b_week_end: false,
      price_cost_b2c_week_end: false,
      price_cost_b2b_day_end: false,
      cost_b2c_week_end: false,
      price_day_b2c: false,
      sellingApply: false,
      reservation_number: "",
      selectedRooms: [],
      rooms: [],
      start_date: null,
      end_date: null,
      hotel_id: null,
      // meals: [],
      fromDateSeasons: null,
      toDateSeasons: null,
      fromDate: null,
      toDate: null,
      number_of_nights: null,
      contract_weekends: [],
      week_end: [],
      season_id: this.$route.params.season_id,
      contract_id: this.$route.params.id,
      rate_id: this.$route.params.rate_id,
      fetchedRooms: [],
      fathy: [{}],
      hotelRooms: [],
      meals: [],
      hotelRoomArray: [],
      hotelViewArray: [],
      mealsArray: [],
      meal: [],
      hotelRoom: [],
      watchVariable: true,
      hotelWatch: true,
      hotelRoomViews: [],
      ViewTypeIds: [],
      daysArray: [],
      empolyment: [],
      employees_id: [],
    };
  },
  computed: {
    selectedDays() {
      const selectedDays = [];
      if (this.fromDate && this.toDate && this.number_of_nights > 0) {
        const fromDate = new Date(this.fromDate);
        for (let i = 0; i < this.number_of_nights; i++) {
          const currentDate = new Date(fromDate);
          currentDate.setDate(fromDate.getDate() + i);
          selectedDays.push({
            date: currentDate.toISOString().split("T")[0],
            dayOfWeek: this.getDayOfWeek(currentDate.getDay()),
          });
        }
      }
      return selectedDays;
    },
  },
  watch: {
    // fromDate: "calculateNumberOfDays",
    toDate: "calculateNumberOfDays",
    hotelRoom: {
      immediate: false,
      handler(newValue, oldValue) {
        if (this.hotelWatch) {
          this.hotelWatch = false;
          return;
        } else {
          // Find deleted rooms
          const deletedRooms = oldValue.filter(
            (oldItem) =>
              !newValue.some((newItem) => oldItem.room.id === newItem.room.id)
          );
          // Remove deleted rooms from the array
          deletedRooms.forEach((deletedItem) => {
            const index = this.hotelRoomArray.findIndex(
              (room) => room.id === deletedItem.room.id
            );
            if (index !== -1) {
              this.hotelRoomArray.splice(index, 1);
            }
          });

          // Find deleted views
          const deletedViews = oldValue.filter(
            (oldItem) =>
              !newValue.some(
                (newItem) => oldItem.view_type.id === newItem.view_type.id
              )
          );
          // Remove deleted views from the array
          deletedViews.forEach((deletedItem) => {
            const index = this.hotelViewArray.findIndex(
              (view) => view.id === deletedItem.view_type.id
            );
            if (index !== -1) {
              this.hotelViewArray.splice(index, 1);
            }
          });
          if (this.hotelRoomArray.length == 0) {
            this.hotelViewArray = [];
          }
          newValue.forEach((selectedItem) => {
            if (selectedItem.room && selectedItem.view_type) {
              const roomExists = this.hotelRoomArray.some(
                (room) => room.id === selectedItem.room.id
              );
              if (!roomExists) {
                const roomWithAdditionalProps = {
                  ...selectedItem.room,
                  week_day_cost: 0,
                  week_end_cost: 0,
                  week_end_b2b_price: 0,
                  week_day_b2b_price: 0,
                  week_end_b2c_price: 0,
                  week_day_b2c_price: 0,
                  switchB2b: true,
                  count: 1,
                  switchB2c: true,
                  all: false,
                  specific: false,
                  weekEnd: false,
                  weekDay: false,
                  days:
                    this.hotelRoomArray.length > 0
                      ? this.hotelRoomArray[0].days
                      : [],
                };
                this.hotelRoomArray.push(roomWithAdditionalProps);
              }

              const viewExists = this.hotelViewArray.some(
                (view) => view.id === selectedItem.view_type.id
              );
              if (!viewExists) {
                const viewWithAdditionalProps = {
                  ...selectedItem.view_type,
                  week_day_cost: 0,
                  week_end_cost: 0,
                  week_end_b2b_price: 0,
                  week_day_b2b_price: 0,
                  week_end_b2c_price: 0,
                  week_day_b2c_price: 0,
                  switchB2b: true,
                  switchB2c: true,
                  totalB2bB2cEnd: 0,
                  totalB2bB2cDay: 0,
                };
                this.hotelViewArray.push(viewWithAdditionalProps);
              }
            }
          });
        }
        this.watchVariable = false; // Reset watchVariable to allow future executions
      },
    },

    meal: {
      immediate: false,
      handler(newValue, oldValue) {
        if (this.watchVariable) {
          this.watchVariable = false;
          return;
        } else {
          // Find deleted items
          const deletedItems = oldValue.filter(
            (oldItem) =>
              !newValue.some(
                (newItem) => oldItem.meal_type_id === newItem.meal_type_id
              )
          );
          // Remove deleted items from the array
          deletedItems.forEach((deletedItem) => {
            const index = this.mealsArray.findIndex(
              (meal) => meal.meal_type_id === deletedItem.meal_type_id
            );
            if (index !== -1) {
              this.mealsArray.splice(index, 1);
            }
          });
          newValue.forEach((selectedItem) => {
            const exists = this.mealsArray.some(
              (meal) => meal.meal_type_id === selectedItem.meal_type_id
            );
            if (!exists) {
              const mealWithAdditionalProps = {
                ...selectedItem,
                include: false,
                week_day_cost: 0,
                week_end_cost: 0,
                week_end_b2b_price: 0,
                week_day_b2b_price: 0,
                week_end_b2c_price: 0,
                week_day_b2c_price: 0,
                totalB2bB2cEnd: 0,
                totalB2bB2cDay: 0,
              };
              this.mealsArray.push(mealWithAdditionalProps);
            }
          });
        }
      },
    },
  },
  methods: {
    setFeatureTitle() {
      this.Form.terms = this.languages.map((language) => ({
        lang: language,
        name: "",
      }));
    },
    FetchEmploy() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_employees").then(({ data }) => {
        this.empolyment = data.data.data;
        this.loading = false;
        //  console.log(this.empolyment)
      });
    },
    filterDaysMethod(room) {
      console.log(room.b2b_weekday);
      console.log("FromDate:", moment(room.fromDate).format("YYYY-MM-DD"));
      console.log("ToDate:", moment(room.toDate).format("YYYY-MM-DD"));

      if (room.filterDays === "weekend") {
        room.days.forEach((day) => {
          if (day.is_weekend && room.b2b_weekend != "") {
            day.b2b_price = room.b2b_weekend;
          }
          if (day.is_weekend && room.b2c_weekend != "") {
            day.b2c_price = room.b2c_weekend;
          }
          if (day.is_weekend && room.cost != "") {
            day.cost = room.cost_weekend;
          }
        });
      } else if (room.filterDays === "weekday") {
        room.days.forEach((day) => {
          if (!day.is_weekend && room.b2b_weekday != "") {
            day.b2b_price = room.b2b_weekday;
          }
          if (!day.is_weekend && room.b2c_weekday != "") {
            day.b2c_price = room.b2c_weekday;
          }
          if (!day.is_weekend && room.cost != "") {
            day.cost = room.cost_weekday;
          }
        });
      } else if (room.filterDays == "specific") {
        const fromDate = moment(room.fromDate);
        const toDate = moment(room.toDate);
        room.days.forEach((day) => {
          const dayDate = moment(day.date, "YYYY-MM-DD");
          if (
            dayDate.isSameOrAfter(fromDate, "day") &&
            dayDate.isSameOrBefore(toDate, "day")
          ) {
            if (!day.is_weekend && room.b2b_weekday !== "") {
              day.b2b_price = room.b2b_weekday;
            }
            if (day.is_weekend && room.b2b_weekend !== "") {
              day.b2b_price = room.b2b_weekend;
            }
            if (!day.is_weekend && room.b2c_weekday !== "") {
              day.b2c_price = room.b2c_weekday;
            }
            if (day.is_weekend && room.b2c_weekend !== "") {
              day.b2c_price = room.b2c_weekend;
            }
            if (!day.is_weekend && room.cost !== "") {
              day.cost = room.cost;
            }
            if (day.is_weekend && room.cost_weekend !== "") {
              day.cost = room.cost_weekend;
            }
            if (!day.is_weekend && room.cost_weekday !== "") {
              day.cost = room.cost_weekday;
            }
          }
        });
      } else {
        room.days.forEach((day) => {
          if (
            !day.is_weekend &&
            room.b2b_weekday !== "" &&
            room.b2b_weekday >= 0
          ) {
            day.b2b_price = room.b2b_weekday;
          }
          if (
            day.is_weekend &&
            room.b2b_weekend !== "" &&
            room.b2b_weekend >= 0
          ) {
            day.b2b_price = room.b2b_weekend;
          }
          if (
            !day.is_weekend &&
            room.b2c_weekday !== "" &&
            room.b2c_weekday >= 0
          ) {
            day.b2c_price = room.b2c_weekday;
          }
          if (
            day.is_weekend &&
            room.b2c_weekend !== "" &&
            room.b2c_weekend >= 0
          ) {
            day.b2c_price = room.b2c_weekend;
          }
          if (!day.is_weekend && room.cost !== "" && room.cost >= 0) {
            day.cost = room.cost_weekday;
          }
          if (day.is_weekend && room.cost !== "" && room.cost >= 0) {
            day.cost = room.cost_weekend;
          }
        });
      }
    },
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    totalB2bB2cEnd(value) {
      value.week_end_b2c_price = value.totalB2bB2cEnd;
      value.week_end_b2b_price = value.totalB2bB2cEnd;
    },
    totalB2bB2cDay(value) {
      value.week_day_b2b_price = value.totalB2bB2cDay;
      value.week_day_b2c_price = value.totalB2bB2cDay;
    },
    fetchHotelRooms(id) {
      axios
        .post("/fetch_custome_hotel_room_view", { hotel_id: id })
        .then(({ data }) => {
          this.hotelRooms = data.data;
        });
    },
    fetchMealsTypes() {
      axios
        .post("/fetch_meal_types")
        .then(({ data }) => {
          this.meals = data.data.data;
          // console.log(this.meals , "meals");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetchRateCodeData() {
      axios.get(`/season_rate_codes/${this.rate_id}`).then(({ data }) => {
        const responseData = data.data;
        this.name = responseData.name;
        this.fromDate = responseData.start_date;
        this.toDate = responseData.end_date;
        this.country_id = responseData.countries;
        this.rooms = responseData.rooms;
        this.season_id = responseData.season_id;
        this.meal = responseData.meals;
        this.hotelRoom = responseData.hotel_room_views;
        this.mealsArray = responseData.meals;
        this.hotelViewArray = responseData.view_types;
        this.hotelRoomArray = responseData.rooms;
        this.is_stop = responseData;
        // Map terms data to Form.terms
        this.Form.terms = responseData.terms.map((item) => ({
          lang: item.locale,
          name: item.term,
        }));

        this.allNations = responseData.has_nationality === 1;
        this.employees_id = responseData.employess;

        this.hotelViewArray?.forEach((view) => {
          const roomWithAdditionalProps = {
            switchB2b: true,
            switchB2c: true,
            totalB2bB2cEnd: 0,
            totalB2bB2cDay: 0,
          };
          Object.assign(view, roomWithAdditionalProps);
        });

        this.hotelRoomArray?.forEach((room) => {
          const roomWithAdditionalProps = {
            switchB2b: true,
            switchB2c: true,
            count: 1,
          };
          Object.assign(room, roomWithAdditionalProps);
        });

        this.mealsArray?.forEach((meal) => {
          const mealWithAdditionalProps = {
            totalB2bB2cEnd: 0,
            totalB2bB2cDay: 0,
          };
          Object.assign(meal, mealWithAdditionalProps);
        });

        this.hotelRoomViews = responseData.hotel_room_views;
        this.daysArray = responseData.season_ratecode_room_view_days;

        this.daysArray?.forEach((room) => {
          const roomWithAdditionalProps = {
            loader: false,
            filterDays: "",
            b2c_weekend: "",
            b2c_weekday: "",
            b2b_weekend: "",
            b2b_weekday: "",
            fromDate: "",
            toDate: "",
            cost_weekday: "",
            cost_weekend: "",
            filterDaysArray: [],
          };
          Object.assign(room, roomWithAdditionalProps);
        });

        this.loading = false;
      });
    },

    // fetch start && end seasons
    fetch_seasons() {
      let season_id = this.$route.params.season_id;
      // console.log(id);
      axios.get("/seasons/" + season_id).then(({ data }) => {
        this.fromDateSeasons = data.data.start_date;
        this.toDateSeasons = data.data.end_date;
        this.fathy = data.data;
        // console.log(data.data);

        // console.log(data.data);
      });
    },
    // getTypeName(value) {
    //   // Define mappings of value to type names
    //   const nameMap = {
    //     1: "all_rooms",
    //     2: "rooms",
    //     3: "sweets",
    //     // ... add more mappings as needed ...
    //   };
    //   return nameMap[value];
    // },
    // minusQuantity(index) {
    //   if (this.rooms[index].count > 0) {
    //     this.rooms[index].count -= 1;
    //     this.rooms[index].selected = true;
    //   }
    //   if (this.rooms[index].count == 0) {
    //     this.rooms[index].selected = false;
    //   }
    // },
    // addQuantity(index) {
    //   this.rooms[index].count += 1;
    //   this.rooms[index].selected = true;
    // },
    formatDate(value) {
      return moment(value).format("DD- MM");
    },
    // get_rate_codes(contract_id) {
    //   axios
    //     .get(`/get_rate_codes_by_contract_id?contract_id=${contract_id}`)
    //     .then(({ data }) => {
    //       this.rateCode = data.data;
    //     });
    // },
    // resetRooms() {
    //   this.rooms = [];
    //   this.hotelValue = "";
    //   this.country_id = "";
    //   this.contractValue = "";
    //   this.rateValue = "";
    // },
    hotelRoomName(value) {
      return `${value.name}`;
    },
    // get hijri date
    updateFromHijriDate(Date) {
      const hijriFormatter = new Intl.DateTimeFormat(
        "ar-SA-u-ca-islamic-umalqura",
        {
          month: "long",
          day: "numeric",
        }
      );
      return hijriFormatter.format(Date).replace(/\s*هـ$/, "");
    },
    //get day
    getWeekday(date) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
      return weekdays[dayIndex];
    },
    // cal differnce betwwen two dates
    calculateNumberOfDays() {
      if (this.fromDate && this.toDate) {
        const start = new Date(this.fromDate);
        const end = new Date(this.toDate);
        const timeDifference = end.getTime() - start.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        this.number_of_nights = daysDifference;
      } else {
        this.number_of_nights = 0;
      }

      // edit room days
      if (this.rooms.length > 0) {
        this.rooms.forEach((room) => {
          room.selectedDays = this.selectedDays.map((day) => {
            return {
              ...day,
              cost: 0,
              selling_price: 0,
            };
          });
        });
      }
    },
    fetch_Contracts() {
      try {
        let id = this.$route.params.id;
        axios.get("/contracts/" + id).then(({ data }) => {
          this.hotel_id = data.data.hotel.id;
          this.fetchHotelRooms(this.hotel_id);
        });
        // console.log(this.hotel_id);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    },
    // fetch room id by hotel id
    async fetchRooms() {
      try {
        let hotel_id = {
          hotel_id: this.hotel_id,
        };
        await axios
          .post("/get_customization_rooms", hotel_id)
          .then(({ data }) => {
            this.rooms = data.data.rooms.map((room) => {
              //  console.log(this.rooms);
              return {
                ...room,
                quantity: 0,
                selected: false,
                price_cost_b2b_week_end: 0,
                cost_b2b_week_end: 0,
                price_cost_b2c_week_end: 0,
                price_cost_b2b_day_end: 0,
                cost_b2c_week_end: 0,
                price_day_b2c: 0,
                // meals: this.meals.map((meal) => {
                //   return {
                //      ...meal,
                //     checked: false,
                //   };
                // }),
                selectedDays: this.selectedDays.map((day) => {
                  return {
                    ...day,
                    cost: 0,
                    selling_price_b2b: 0,
                    selling_price_b2c: 0,
                  };
                }),
              };
            });
          });
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
      this.editRooms();
    },
    editRooms() {
      this.rooms.forEach((room) => {
        this.fetchedRooms.forEach((fetchedRoom) => {
          if (fetchedRoom.id == room.id) {
            room.selected = true;
            room.quantity = fetchedRoom.count;

            room.cost_b2b_week_end = fetchedRoom.week_end_cost;
            room.cost_b2c_week_end = fetchedRoom.week_day_cost;
            room.price_cost_b2b_week_end = fetchedRoom.week_end_b2b_price;
            room.price_cost_b2b_day_end = fetchedRoom.week_day_b2b_price;
            room.price_cost_b2c_week_end = fetchedRoom.week_end_b2c_price;
            room.price_day_b2c = fetchedRoom.week_day_b2c_price;

            room.selectedDays.forEach((day) => {
              fetchedRoom.days.forEach((fetchedDay) => {
                if (day.date == fetchedDay.date) {
                  day.cost = fetchedDay.cost;
                  day.selling_price_b2b = fetchedDay.b2b_price;
                  day.selling_price_b2c = fetchedDay.b2c_price;
                }
              });
            });
          }
        });
      });
    },
    getDayOfWeek(dayIndex) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[dayIndex];
    },
    editRateCode() {
      this.submitLoader = true;
      const params = new URLSearchParams();
      params.append("name", this.name);
      params.append("start_date", moment(this.fromDate).format("YYYY-MM-DD"));
      params.append("end_date", moment(this.toDate).format("YYYY-MM-DD"));
      this.Form.terms.forEach((ele) => {
        params.append(`term_${ele.lang}`, ele.name);
      });
      params.append("season_id", this.$route.params.season_id);
      // console.log(this.employees_id)
      for (let i = 0; i < this.employees_id.length; i++) {
        params.append(`employees[${i}]`, this.employees_id[i].id);
      }
      if (this.allNations) {
        params.append("has_nationality", 1);
      } else {
        params.append("has_nationality", 0);
        this.country_id.forEach((hotel, index) => {
          // console.log(id);
          params.append(`country_ids[${index}]`, hotel.id);
        });
      }

      this.hotelRoomArray.forEach((room, index) => {
        Object.entries(room).forEach(([key, value]) => {
          if (key == "id") {
            params.append(`rooms[${index}][room_id]`, value);
          }
          // else if (key === "days") {
          //   value.forEach((day, dayIndex) => {
          //     Object.entries(day).forEach(([dayKey, dayValue]) => {
          //       params.append(
          //         `rooms[${index}][days][${dayIndex}][${dayKey}]`,
          //         dayValue
          //       );
          //     });
          //   });
          else if (key == "selectedDays") {
            console.log(value);
          } else {
            params.append(`rooms[${index}][${key}]`, value);
          }
        });
      });
      this.hotelViewArray.forEach((view, index) => {
        Object.entries(view).forEach(([key, value]) => {
          if (key == "id") {
            params.append(`view_types[${index}][view_type_id]`, value);
          } else if (key == "view_type_id") {
            params.append(`view_types[${index}][id]`, value);
          } else if (key == "include") {
            if (value == true) {
              params.append(`view_types[${index}][${key}]`, 1);
            } else {
              params.append(`view_types[${index}][${key}]`, 0);
            }
          } else {
            params.append(`view_types[${index}][${key}]`, value);
          }
        });
      });
      this.hotelRoom.forEach((room, index) => {
        params.append(`hotel_room_view_ids[${index}]`, room.id);
      });
      this.mealsArray.forEach((meal, index) => {
        Object.entries(meal).forEach(([key, value]) => {
          if (key == "include") {
            if (value == true) {
              params.append(`meals[${index}][${key}]`, 1);
            } else {
              params.append(`meals[${index}][${key}]`, 0);
            }
          } else {
            params.append(`meals[${index}][${key}]`, value);
          }
        });
      });
      this.hotelRoom.forEach((room, index) => {
        params.append(`hotel_room_view_ids[${index}]`, room.id);
      });
      axios
        .put(`/season_rate_codes/${this.rate_id}`, params)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.fetchRateCodeData();
          this.submitLoader = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.submitLoader = false;
        });
    },
    updateMealInclude(meal) {
      if (meal.include) {
        meal.week_day_b2b_price = 0;
        meal.week_day_b2c_price = 0;
        meal.week_day_cost = 0;
        meal.week_end_b2b_price = 0;
        meal.week_end_b2c_price = 0;
        meal.week_end_cost = 0;
      }
    },
    updateViewInclude(view) {
      if (view.include) {
        view.week_day_b2b_price = 0;
        view.week_day_b2c_price = 0;
        view.week_day_cost = 0;
        view.week_end_b2b_price = 0;
        view.week_end_b2c_price = 0;
        view.week_end_cost = 0;
      }
    },
    updateDays(days, type, index) {
      // console.log(days);
      days.loader = true;
      if (type == 0) {
        console.log(days);
        Swal.fire({
          title: this.$t("check_delete"),
          text: this.$t("are_you_sure_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_delete"),
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("code", 0);
            formData.append("season_rate_code_room_views_id", days.id);
            axios
              .post(`/update_or_delete_room_view_days`, formData)
              .then(({ data }) => {
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "success",
                });
                this.daysArray.splice(index, 1);
                this.fetchRateCodeData();
                days.loader = false;
              })
              .catch((error) => {
                Swal.fire({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                });
                days.loader = false;
              });
          }
        });
      } else if (type == 1) {
        const formData = new FormData();
        formData.append("code", 1);
        formData.append("season_rate_code_room_views_id", days.id);
        days.days.forEach((day, index) => {
          Object.keys(day).forEach((key) => {
            formData.append(`days[${index}][${key}]`, day[key]);
          });
        });
        axios
          .post("/update_or_delete_room_view_days", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.fetchRateCodeData();
            days.loader = false;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            days.loader = false;
          });
      }
    },
    // fetch type b2b && b2c
  },

  mounted() {
    this.fetch_Contracts();
    this.fetchRateCodeData();
    this.fetch_country();
    this.fetch_seasons();
    this.fetchMealsTypes();
    this.FetchEmploy();
    this.setFeatureTitle(); // Initialize terms with default values
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_season.scss";
</style>
