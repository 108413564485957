<template>
  <div class="flex justify-content-center">
    <button @click="visible = true" class="btn PrimaryButton">
      {{ $t("Stop Selling") }}
    </button>
    <Dialog
      v-model:visible="visible"
      dismissableMask
      modal
      :header="$t('Stop Selling')"
      :style="{ width: '50rem' }"
    >
      <div v-for="(room, index) in daysArray" :key="index">
        <div class="row mb-3" v-if="room.days.length > 0">
          <div class="col-12 d-flex flex-column">
            <div class="d-flex flex-column gap-3">
              <div class="form-group d-flex align-items-center gap-2">
                <input
                  class="form-check-input mt-0"
                  type="radio"
                  name="radioDefault"
                  id="allSelling"
                  value="allSelling"
                  @click="value = 'allSelling'"
                  v-model="room.filterDays"
                />
                <label class="form-label mb-0">{{ $t("all") }}</label>
              </div>
              <div class="form-group d-flex align-items-center gap-2">
                <input
                  class="form-check-input mt-0"
                  type="radio"
                  name="radioDefault"
                  id="specificSelling"
                  value="specificSelling"
                  @click="value = 'specificSelling'"
                  v-model="room.filterDays"
                />
                <label class="form-label mb-0">{{ $t("specific") }}</label>
              </div>

              <div
                v-if="room.filterDays === 'specificSelling'"
                class="row w-100"
              >
                <div class="form-group col-6">
                  <label class="form-label">{{ $t("from") }}</label>
                  <datepicker
                    v-model="room.fromDate"
                    name="durationFrom"
                    @input="getDaysBetween(room)"
                    :placeholder="$t('From')"
                    :disabled-dates="{
                      to: new Date(fromDateSeasons),
                      from: new Date(toDateSeasons),
                    }"
                  ></datepicker>
                </div>
                <div class="form-group col-6">
                  <label class="form-label">{{ $t("to") }}</label>
                  <datepicker
                    v-model="room.toDate"
                    name="durationTo"
                    @input="getDaysBetween(room)"
                    :placeholder="$t('To')"
                    :disabled-dates="{
                      to: new Date(fromDateSeasons),
                      from: new Date(toDateSeasons),
                    }"
                  ></datepicker>
                </div>
              </div>

              <div class="form-group d-flex align-items-center gap-2">
                <input
                  class="form-check-input mt-0"
                  type="radio"
                  name="radioDefault"
                  id="weekdaySelling"
                  value="weekdaySelling"
                  @click="value = 'weekdaySelling'"
                  v-model="room.filterDays"
                />
                <label class="form-label mb-0">{{ $t("weekday") }}</label>
              </div>
              <div class="form-group d-flex align-items-center gap-2">
                <input
                  class="form-check-input mt-0"
                  type="radio"
                  name="radioDefault"
                  id="weekendSelling"
                  value="weekendSelling"
                  @click="value = 'weekendSelling'"
                  v-model="room.filterDays"
                />
                <label class="form-label mb-0">{{ $t("weekend") }}</label>
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn PrimaryButton"
          style="margin-inline-start: unset"
          @click="updateDaysSelling(room, 1)"
        >
          <i class="fa-solid fa-spinner fa-spin-pulse" v-if="room.loader"></i>
          <span class="d-flex align-items-center gap-1" v-else
            ><i class="fa-solid fa-floppy-disk"></i>
            {{ $t("Stop Selling") }}</span
          >
        </button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import moment from "moment";
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "stopSelling-Dialog",
  components: { Dialog, Datepicker },
  data() {
    return {
      visible: false,
      value: "allSelling",
    };
  },
  props: ["daysArray", "fetchRateCodeData"],
  methods: {
    getDaysBetween(room) {
      if (room.fromDate && room.toDate) {
        const from = moment(room.fromDate);
        const to = moment(room.toDate);
        const daysArray = [];

        for (let m = moment(from); m.isSameOrBefore(to); m.add(1, "days")) {
          daysArray.push(m.format("YYYY-MM-DD"));
        }

        return daysArray; // إرجاع المصفوفة بدلاً من الطباعة
      }
      return []; // إرجاع مصفوفة فارغة إذا لم تتوفر التواريخ
    },

    formatDate(value) {
      return moment(value).format("DD- MM");
    },
    updateFromHijriDate(Date) {
      const hijriFormatter = new Intl.DateTimeFormat(
        "ar-SA-u-ca-islamic-umalqura",
        {
          month: "long",
          day: "numeric",
        }
      );
      return hijriFormatter.format(Date).replace(/\s*هـ$/, "");
    },
    getWeekday(date) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
      return weekdays[dayIndex];
    },
    updateDaysSelling(days, type) {
      days.loader = true;
      if (type == 1) {
        const formData = new FormData();
        formData.append("season_rate_code_room_view_id", days.id);

        const filteredDays = this.getDaysBetween(days); // الحصول على الأيام بين التواريخ
        const selectedDays = filteredDays.map((day) =>
          moment(day).format("YYYY-MM-DD")
        );
        // console.log(selectedDays, "selectedDays");
        // console.log(filteredDays, "filteredDays");
        // console.log(days, "days");

        days.days.forEach((day, index) => {
          if (this.value == "weekendSelling") {
            if (day.is_weekend === true) {
              formData.append(`days[${index}][id]`, day.id);
              formData.append(`days[${index}][date]`, day.date);
            }
          } else if (this.value == "weekdaySelling") {
            if (day.is_weekend === false) {
              formData.append(`days[${index}][id]`, day.id);
              formData.append(`days[${index}][date]`, day.date);
            }
          } else if (this.value === "specificSelling") {
            selectedDays.filter((selectedDay) => {
              if (day.date === selectedDay) {
                formData.append(`days[${index}][id]`, day.id);
                formData.append(`days[${index}][date]`, day.date);
              }
            });
          } else {
            formData.append(`days[${index}][id]`, day.id);
            formData.append(`days[${index}][date]`, day.date);
          }
        });

        axios
          .post("/update_season_rate_code_room_view_day_stops", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.fetchRateCodeData();
            days.loader = false;
            this.visible = false;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            days.loader = false;
            this.visible = false;
          });
      }
    },
  },
};
</script>
